import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: () => import( /* webpackChunkName: "Home" */ '../views/Home.vue')
  },
  {
    path: '/card',
    name: 'card',
    component: () => import( /* webpackChunkName: "card" */ '../views/card.vue')
  },
  {
    path: '/details',
    name: 'details',
    component: () => import( /* webpackChunkName: "details" */ '../views/details.vue')
  },
  {
    path: '/trail',
    name: 'trail',
    component: () => import( /* webpackChunkName: "trail" */ '../views/trail.vue')
  },
  {
    path: '/articleInfo',
    name: 'articleInfo',
    component: () => import( /* webpackChunkName: "details" */ '../views/articleInfo.vue')
  },
  {
    path: '/cardDetails',
    name: 'cardDetails',
    component: () => import( /* webpackChunkName: "cardDetails" */ '../views/cardDetails.vue')
  },
  {
    path: '/bind',
    name: 'bind',
    component: () => import( /* webpackChunkName: "cardDetails" */ '../views/bind.vue')
  },
  {
    path: '/flow',
    name: 'flow',
    component: () => import( /* webpackChunkName: "cardDetails" */ '../views/flow.vue')
  },
  {
    path: '/mine',
    name: 'mine',
    component: () => import( /* webpackChunkName: "cardDetails" */ '../views/mine.vue')
  },
]

const router = new VueRouter({
  // mode: 'history',
  routes
})

export default router