import Vue from 'vue'

function S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
}
function guid() {
    return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
}
Vue.prototype.$pageId = guid()
function getPhone(tel) {
    tel = "" + tel;
    let tel1 = tel.substr(0, 3) + "****" + tel.substr(7)
    return tel1
}
Vue.prototype.$getPhone = getPhone
Vue.prototype.$dateFormat = (timestamp, formats) => {
    // formats格式包括
    // 1. Y-m-d
    // 2. Y-m-d H:i:s
    // 3. Y年m月d日
    // 4. Y年m月d日 H时i分
    formats = formats || "Y-m-d";

    let zero = function (value) {
        if (value < 10) {
            return "0" + value;
        }
        return value;
    };
    let myDate = timestamp ? new Date(Number(timestamp)) : new Date();
    let year = myDate.getFullYear();
    let month = zero(myDate.getMonth() + 1);
    let day = zero(myDate.getDate());

    let hour = zero(myDate.getHours());
    let minite = zero(myDate.getMinutes());
    let second = zero(myDate.getSeconds());

    return formats.replace(/Y|m|d|H|i|s/gi, function (matches) {
        return {
            Y: year,
            m: month,
            d: day,
            H: hour,
            i: minite,
            s: second,
        }[matches];
    });
}
