import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
import createPersistedState from 'vuex-persistedstate'
export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    json: {}
  },
  mutations: {
    setJson(state, json) {
      state.json = json
    }
  },
  actions: {},
  modules: {}
})