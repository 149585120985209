<template>
  <div id="app">
    <router-view ref="view" />
    <van-tabbar v-model="active"
                @change="onChange">
      <van-tabbar-item :icon="getIcon(menu)"
                       v-for="(menu, m) in menuList"
                       :key="m">{{menu.name}}</van-tabbar-item>
      <!-- home-o -->
      <!-- <van-tabbar-item icon="goods-collect-o">流量宝</van-tabbar-item>
      <van-tabbar-item icon="tv-o">官网</van-tabbar-item>
      <van-tabbar-item icon="apps-o">产品介绍</van-tabbar-item> -->
    </van-tabbar>
  </div>
</template>

<script>
export default {
  data () {
    return {
      active: -1,
      form: {},
      menuList: []
    }
  },
  computed: {
  },
  created () {
    if (this.$route.query.tenantId) {
      localStorage.setItem("tenantId", this.$route.query.tenantId);
    }
  },
  mounted () {
  },
  watch: {
    active (val) {
      let menu = this.menuList[val] || {}
      if (menu.type === 'module') { // 内置模块
        if (menu.module === 'home') { // 首页
          if (this.$route.name === 'Home') {
            return
          }
          let businessCardId = localStorage.getItem("businessCardId")
          this.$router.push("/?id=" + businessCardId)
        } else if (menu.module === 'mine') { // 我的
          this.$router.push({ path: '/mine' })
        } else if (menu.module === 'flow') { // 目前除了“首页”、“我的”，只有“流量宝”一个内置模块
          this.$router.push({ path: "/flow" })
        }
      } else if (menu.type === 'link' && menu.linkUrl) { // 跳转链接
        if (menu.linkUrl.indexOf("http") == -1) {
          window.location.href = "http://" + menu.linkUrl
        } else {
          window.location.href = menu.linkUrl
        }
      } else if (menu.type === 'article') { // 关联文章
        this.$router.push(
          `/details?id=${localStorage.getItem("ladingPageId") || 0
          }&businessCardId=${localStorage.getItem("businessCardId")
          }&channelId=${localStorage.getItem("channelId") || 0
          }&articleId=${menu.articleId}`
        )
      }
    },
    $route: {
      handler: function (val, oldVal) {
        this.$nextTick(() => {
          this.$refs.view.$emit("change")
          this.getMenuList()
        });
      },
      // 深度观察监听
      deep: true,
    },
  },
  methods: {
    getIcon (menu) {
      if (menu.type === 'module' && menu.module === 'home') {
        return 'home-o'
      } else if (menu.type === 'module' && menu.module === 'mine') {
        return 'user-o'
      } else if (menu.type === 'module' && menu.module === 'flow') {
        return 'goods-collect-o'
      } else if (menu.type === 'link') {
        return 'tv-o'
      } else if (menu.type === 'article') {
        return 'notes-o'
      }
    },
    onChange (val) {
      setTimeout(() => {
        this.active = -1;
      }, 2000);
    },
    getMenuList () {
      console.log('获取菜单', this.$route, this.menuList)
      if (this.menuList.length) {
        return
      }
      this.$api
        .post("/api/crm/businessCardMenu/treeListByCardId", {
          businessCardId: this.$route.query.id || localStorage.getItem("businessCardId")
        })
        .then((res) => {
          if (res && res.code === "C0000") {
            if (res.result && res.result.length) {
              this.menuList = res.result
            } else {
              this.menuList = [
                {
                  name: "首页",
                  module: 'home',
                  type: 'module',
                  children: []
                },
                {
                  name: "我的",
                  module: 'mine',
                  type: 'module',
                  children: []
                }
              ]
            }
          }
        })
    },
  }
}
</script>

<style lang="less">
.skeleton {
  padding: 20px;
}
.van-toast__text {
  text-align: left;
}
#app {
  padding-bottom: 100px;
}
.content img {
  width: 100% !important;
}
</style>
