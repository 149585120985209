import axios from 'axios'
import VueCookies from 'vue-cookies' // cookies
axios.defaults.headers.common['token'] = VueCookies.get('token');
import {
  Toast
} from 'vant';
axios.interceptors.request.use(config => {
  return config
}, err => {
  return Promise.reject(err)
})

function checkStatus(res) {
  if ((res.status === 200 || res.status === 304)) { //当状态正常是返回原样的数据
    return res.data
  } else {
    Toast(res.message)
    return false // 状态不正常时可以返回自己自定义的一些格式或者状态什么的
  }
}

function errStatus(res) {
  Toast(res.response.data ? res.response.data.message : res.response.message)
  return false
}
export default {
  file(url, data) {
    if (!url) return
    return axios({
      method: 'post',
      url,
      data: {
        ...data,
        tenantId: localStorage.getItem("tenantId")
      },
      headers: {
        'Content-Type': 'multipart/form-data'
      },
    }).then(checkStatus).catch(errStatus)
  },
  get(url, params) { //返回封装后的 get 方法
    if (!url) return
    return axios({
      method: 'get',
      url,
      params: {
        ...params,
        tenantId: localStorage.getItem("tenantId")
      },
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then(checkStatus).catch(errStatus)
  },
  post(url, data) { //返回封装后的 post 方法
    if (!url) return
    return axios({
      method: 'post',
      url: `${url}?tenantId=${localStorage.getItem("tenantId")}`,
      data: data || {},
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      }
    }).then(checkStatus).catch(errStatus)
  }
}